import { mapGetters, mapActions, mapMutations } from 'vuex'

import parseData from '@/utils/parseData'
import tooltips from '@/utils/tooltips'
import calcMetrics from '@/utils/calcMetrics'
import { alerts, statuses, priorities, funnel, actions, types, intents } from '@/utils/clusterVariables.js'

export default {
  name: 'Plan',
  data () {
    return {
      isLoading: {
        excludeKeyword: false,
        resetKeyword: false,
        addTopic: false,
        renameTopic: false,
        excludeTopic: false,
        deleteTopic: false,
        moveKeywords: false,
        moveArticles: false,
        renameArticle: false,
        mergeArticles: false,
        addToMap: false,
        deleteArticles: false,
        dismissArticles: false,
        changeArticleTags: false,
        newArticle: false,
        score: false,
        articles: false,
        keywords: false,
        updateTarget: false,
        lockingArticle: false,
        excludePages: false,
        updatePageAction: false,
        articleUrls: false,
        pageArticles: false,
        alerts: false,
        updatePageMergeWith: false,
        mutatingArticles: false
      },

      tableArticles: {
        isLoading: false,
        headers: [
          { text: 'URL', value: 'article_url' },
          { text: 'Cluster', value: 'title' },
          { text: 'Status', value: 'status' },
          { text: 'Page type', value: 'type' },
          { text: 'Search Volume', value: 'estimated_search_volume' },
          { text: 'Cluster Impressions', value: 'google_impressions_last' },
          { text: 'Actions', value: 'actions' }
        ]
      },
      tableArticlesOptions: {
        itemsPerPage: 50,
        page: 1,
        sortBy: ['estimated_search_volume'],
        sortDesc: [true]
      },

      selectedTopic: null,
      selectedKeywords: [],
      selectedArticles: [],
      selectedPages: [],
      selectedKeyword: [],
      selectAllArticles: false,
      selectAllPages: false,
      selectableTargetUrls: [],
      selectableTargetArticles: [],

      topicFilter: null,
      actionFilter: '',
      newOrExistingFilter: null,
      trending: false,
      losing: false,

      switchGridView: false,
      showExistingPageShortcuts: true,
      showNewPageShortcuts: false,
      showTopics: false,

      statuses,
      priorities,
      funnel,
      alerts,
      actions,

      metrics: [
        { text: 'Search Volume', value: 'estimated_search_volume', ranges: [0, 100, 1000, 10000] },
        { text: 'Potential Clicks', value: 'potential_clicks', ranges: [0, 100, 1000, 10000] },
        { text: '# of Keywords', value: 'keyword_count', ranges: [0, 10, 30, 100] },
        { text: 'URL Impressions', value: 'page_impressions_last', ranges: [0, 100, 1000, 10000] },
        { text: 'URL Clicks', value: 'page_clicks_last', ranges: [0, 10, 100, 1000] },
        { text: 'URL CTR', value: 'page_ctr_last', ranges: [0, 1, 5, 10] },
        { text: 'URL Rank', value: 'page_position_last', ranges: [1, 3, 10, 30] },
        { text: 'URL Impressions Change', value: 'page_impressions_change', ranges: [-1000, -100, 0, 100, 1000] },
        { text: 'URL Clicks Change', value: 'page_clicks_change', ranges: [-100, -10, 0, 10, 100] },
        { text: 'URL CTR Change', value: 'page_ctr_change', ranges: [-10, -5, 0, 5, 10] },
        { text: 'URL Rank Change', value: 'page_position_change', ranges: [-30, -10, 0, 10, 30] },
        { text: 'Cluster Match Impressions', value: 'target_impressions', ranges: [0, 100, 1000, 10000] },
        { text: 'Cluster Match Clicks', value: 'target_clicks', ranges: [0, 10, 100, 1000] },
        { text: 'Cluster Match CTR', value: 'target_ctr', ranges: [0, 1, 5, 10] },
        { text: 'Cluster Match Rank', value: 'target_position', ranges: [1, 3, 10, 30] },
        { text: 'Cluster Impressions', value: 'google_impressions_last', ranges: [0, 100, 1000, 10000] },
        { text: 'Cluster Clicks', value: 'google_clicks_last', ranges: [0, 10, 100, 1000] },
        { text: 'Cluster CTR', value: 'google_ctr_last', ranges: [0, 1, 5, 10] },
        { text: 'Cluster Rank', value: 'google_position_last', ranges: [1, 3, 10, 30] },
        { text: 'Cluster Impressions Change', value: 'google_impressions_change', ranges: [-1000, -100, 0, 100, 1000] },
        { text: 'Cluster Clicks Change', value: 'google_clicks_change', ranges: [-100, -10, 0, 10, 100] },
        { text: 'Cluster CTR Change', value: 'google_ctr_change', ranges: [-10, -5, 0, 5, 10] },
        { text: 'Cluster Rank Change', value: 'google_position_change', ranges: [-30, -10, 0, 10, 30] },
        { text: 'Cluster Match Rate', value: 'cluster_match_rate', ranges: [0, 10, 30, 100] }
      ],
      dimensions: [
        { text: 'Action', value: 'suggested_action', type: 'select', options: actions },
        { text: 'Status', value: 'status', type: 'select', options: statuses },
        { text: 'Priority', value: 'priority', type: 'select', options: priorities },
        { text: 'Funnel', value: 'funnel', type: 'select', options: funnel },
        { text: 'Page type', value: 'type', type: 'select', options: types },
        { text: 'Search intent', value: 'intent', type: 'select', options: intents },
        { text: 'Publish date', value: 'published_at', type: 'date' },
        { text: 'Status code', value: 'status_code', type: 'select', options: [200, 301, 302, 308, 403, 404, 503] },
        { text: 'Alerts', value: 'alerts', type: 'select-boolean', options: alerts }
      ],

      metricFilters: [
        { text: 'Search Volume', value: 'estimated_search_volume', ranges: [0, 100, 1000, 10000], min: null, max: null },
        { text: 'Potential Clicks', value: 'potential_clicks', ranges: [0, 100, 1000, 10000], min: null, max: null }
      ],
      dimensionFilters: [
        { text: 'Publish date', value: 'published_at', type: 'date', filter: [null, null] },
        { text: 'Priority', value: 'priority', type: 'select', options: priorities, filter: ['high', 'not set'] }
      ],
      textFilters: [
        { text: 'Cluster', value: 'title', filter: '' },
        { text: 'URL', value: 'article_url', filter: '' },
        { text: 'Keyword', value: 'keyword', filter: '' }
      ],

      selectedMetrics: [
        { text: 'Search Volume', value: 'estimated_search_volume' },
        { text: 'URL Impressions', value: 'page_impressions_last' }
      ],
      selectedDimensions: [
        { text: 'Action', value: 'suggested_action' }
      ],

      rules: {
        topicName: value => this.topics?.filter(topic => topic.name === value).length === 0 || 'Topic name already exists.',
        articleName: value => this.articles?.filter(article => article.title === value).length === 0 || 'Article title already exists.'
      },

      topicNewName: null,
      articleNewName: null,
      keywordsNewArticle: null,
      articlesNewTopicId: null,
      articlesNewPriority: null,
      articlesNewFunnel: null,
      articlesNewStatus: null,
      articlesNewPageId: null,
      pageNewArticleId: null,
      articleChangeUrl: null,
      topicAddAndMoveArticle: false,
      articleAddAndMoveKeyword: false,
      articlePage: 1,
      articlePageLength: 9,
      articleAddForm: false,
      topicAddForm: false,

      showTopicRename: false,
      showTopicExclude: false,
      showTopicReset: false,
      showTopicAdd: false,
      showKeywordMove: false,
      showArticleRename: false,
      showArticlePriority: false,
      showArticleFunnel: false,
      showArticleStatus: false,
      showArticleMove: false,
      showArticleAdd: false
    }
  },
  mixins: [calcMetrics],
  computed: {
    ...mapGetters([
      'topics',
      'topic',
      'score',
      'articles',
      'articleCounts',
      'keywords',
      'activeContentplan',
      'pages',
      'articleUrls',
      'pageArticles'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    snackbarKeywords () {
      return this.selectedKeywords?.length > 0
    },
    snackbarArticles () {
      return this.selectedArticles?.length > 0
    },
    snackbarPages () {
      return this.selectedPages?.length > 0
    },
    snackbarMetrics () {
      return this.selectedKeywords?.length > 0 || this.selectedArticles?.length > 0 || this.selectedPages?.length > 0
    },
    selectableArticles () {
      return this.articles.map(a => ({ text: a.title, value: a.article_id }))
    },
    selectableTopics () {
      if (!this.topics) return

      return this.topics.map(a => ({ text: a.name, value: a.id, count: a.article_count })).filter(a => (a.value !== this.topicFilter))
    },
    newPagesCount () {
      if (!this.articleCounts?.pageCounts) return
      return this.articleCounts.pageCounts.find(x => x.name === 'new')?.count || 0
    },
    existingPagesCount () {
      if (!this.articleCounts?.pageCounts) return
      return this.articleCounts.pageCounts.find(x => x.name === 'existing')?.count || 0
    },
    topicTotalCount () {
      if (!this.articleCounts?.topicCounts) return
      return this.articleCounts.topicCounts.map(x => x.count).reduce((a, b) => a + b, 0)
    },
    totalCount () {
      return this.newPagesCount + this.existingPagesCount
    },
    activeFilterCount () {
      let count = 0

      if (this.titleFilter) count++
      if (this.keywordFilter) count++
      if (this.urlFilter) count++
      if (this.volumeFilter[0] || this.volumeFilter[1]) count++
      if (this.positionFilter[0] || this.positionFilter[1]) count++
      if (this.ctrFilter[0] || this.ctrFilter[1]) count++
      if (this.potentialFilter[0] || this.potentialFilter[1]) count++
      if (this.statusFilter.length) count++
      if (this.priorityFilter.length) count++
      if (this.funnelFilter.length) count++
      if (this.alertsFilter.length) count++

      return count
    },
    createFiltersObject () {
      const filters = {
        contentplanId: this.contentplanId,
        filter_contentplanId: this.contentplanId,
        offset: this.switchGridView ? 0 : (this.itemsPage - 1) * this.itemsPerPage,
        limit: this.itemsPage * this.itemsPerPage,
        orderByColumn: this.sortBy,
        orderByDirection: this.sortDesc
      }

      // Process metric filters
      this.metricFilters.forEach((f) => {
        if (f.min !== null) {
          filters[`filter_min_${f.value}`] = parseInt(f.min)
        }
        if (f.max !== null) {
          filters[`filter_max_${f.value}`] = parseInt(f.max)
        }
      })

      // Process dimension filters
      this.dimensionFilters.forEach((f) => {
        if (f.type === 'select') {
          filters[`filter_${f.value}`] = f.filter
        }
        if (f.type === 'date') {
          if (f.filter[0]) {
            filters[`filter_min_${f.value}`] = f.filter[0]
          }
          if (f.filter[1]) {
            filters[`filter_max_${f.value}`] = f.filter[1]
          }
        }
      })

      // Process text filters
      this.textFilters.forEach((f) => {
        if (f.filter) {
          filters[`filter_${f.value}`] = f.filter
        }
      })

      if (this.topicFilter) {
        filters.filter_topicId = this.topicFilter
      }

      if (this.actionFilter) {
        filters.filter_action = this.actionFilter
      }

      if (this.newOrExistingFilter) {
        if (this.newOrExistingFilter === 'new') {
          filters.filter_new = true
        } else {
          filters.filter_existing = true
        }
      }

      if (this.switchGridView) {
        filters.getKeywords = true
      }
      // console.log('filters', filters)

      return filters
    },
    itemsPerPage () {
      if (this.switchGridView) return this.articlePageLength

      return this.tableArticlesOptions.itemsPerPage
    },
    itemsPage () {
      if (this.switchGridView) return this.articlePage

      return this.tableArticlesOptions.page
    },
    sortBy () {
      let sortBy
      if (this.switchGridView) {
        sortBy = this.selectedMetrics[0].value
      } else {
        sortBy = this.tableArticlesOptions.sortBy[0]
      }

      if (sortBy === 'undefined') {
        sortBy = null
      }

      return sortBy
    },
    sortDesc () {
      if (this.switchGridView) return 'desc'

      return this.tableArticlesOptions.sortDesc[0] ? 'desc' : 'asc'
    },
    leftNavActions () {
      return this.actions.filter(a => a.value !== 'not-enough-data')
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    },
    async tableArticlesOptions () {
      await this.mutateArticles()
    }
  },
  methods: {
    parseData,
    tooltips,
    ...mapActions([
      'loadTopics',
      'loadArticlesAdvanced',
      'updateKeywords',
      'updatePages',
      'addTopic',
      'updateTopics',
      'updateArticles',
      'addArticle',
      'loadScoreAndCommit',
      'loadPageArticles',
      'loadArticleUrls',
      'analyzeLandingPage',
      'recalculateAlerts'
    ]),

    ...mapMutations([
      'topicNew',
      'topicRename',
      'topicExclude',
      'keywordMoveCluster',
      'keywordExclude',
      'articleExclude',
      'articleMove',
      'articleChangeTag',
      'articleNew',
      'pageExclude',
      'pageMatchLock',
      'pageMatchUpdate'
    ]),

    // RENDER DATA
    async loadData () {
      try {
        this.isLoading.articles = true
        this.tableArticles.isLoading = true
        document.title = 'Plan | ContentGecko'
        this.articlePage = 1
        await Promise.all([
          this.loadTopics({
            contentplanId: this.contentplanId
          }),
          this.mutateArticles()
        ])
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading.articles = false
        this.tableArticles.isLoading = false
      }
    },
    async mutateArticles (delay) {
      if (this.isLoading.mutatingArticles) return
      this.isLoading.mutatingArticles = true
      this.tableArticles.isLoading = true
      this.isLoading.articles = true

      try {
        if (delay) {
          setTimeout(async () => {
            await this.loadArticlesAdvanced(this.createFiltersObject)
            this.isLoading.mutatingArticles = false
            this.tableArticles.isLoading = false
            this.isLoading.articles = false
          }, delay)
        } else {
          await this.loadArticlesAdvanced(this.createFiltersObject)
          this.isLoading.mutatingArticles = false
          this.tableArticles.isLoading = false
          this.isLoading.articles = false
        }
      } catch (error) {
        console.log(error)
        this.isLoading.mutatingArticles = false
      }
    },
    async loadNewArticles (isVisible) {
      if (!isVisible) return
      if (this.isLoading.keywords) return
      this.isLoading.articles = true
      this.articlePage++
      this.mutateArticles()
      this.isLoading.articles = false
    },
    async getScore () {
      if (this.selectedPages.length < 1 && this.selectedArticles.length < 1 && this.selectedKeywords.length < 2) return 'N/a'
      if (this.selectedArticles.length > 10 || this.selectedPages.length > 10) return 0

      this.isLoading.score = true

      await this.loadScoreAndCommit({
        contentplanId: this.contentplanId,
        articleId: this.selectedArticles?.length ? this.selectedArticles?.map(el => el.article_id) : this.selectedPages?.map(el => el.article_id),
        keywordId: this.selectedKeywords.map(el => el.keyword_id)
      })

      this.isLoading.score = false
    },
    getArticleKeywords (articleId) {
      if (!this.keywords || !this.articles || this.keywords?.keywordCounts) return []

      const tempKeywords = this.keywords
      if (!tempKeywords.length) return []

      return tempKeywords.filter(k => k.article_id === articleId)
    },
    topicArticlesCount (topicId) {
      if (!this.articleCounts?.topicCounts) return

      return this.articleCounts.topicCounts.find(a => a.topic_id === topicId)?.count || 0
    },
    pageActionCount (action) {
      if (!this.articleCounts?.pageCounts) return

      return this.articleCounts.pageCounts.find(a => a.name === action)?.count || 0
    },
    actionChipColor (item) {
      if (!item) return
      const action = item.action ? item.action : item.suggested_action
      if (item.action) {
        if (action === 'leave') return 'green lighten-5'
        if (action === 'delete') return 'red lighten-5'
        if (action === 'merge') return 'pink lighten-5'
        if (action === 'update') return 'orange lighten-5'
      }
      if (item.suggested_action) {
        if (action === 'leave') return 'green lighten-4'
        if (action === 'delete') return 'red lighten-4'
        if (action === 'merge') return 'pink lighten-4'
        if (action === 'update') return 'orange lighten-4'
      }
    },
    formatDate (date) {
      if (!date) return
      return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
    },
    suggestedActionText (action) {
      return this.actions.find(a => a.value === action)?.text
    },
    capitalizeFirstChar (string) {
      if (!string?.length) return
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    // QUICK FILTERS
    selectedMetricIcon (metric) {
      if (!this.selectedMetrics?.map(m => m.value)?.includes(metric.value)) return
      if (this.selectedMetrics[0]?.value === metric.value) return 'mdi-numeric-1'
      if (this.selectedMetrics[1]?.value === metric.value) return 'mdi-numeric-2'
    },
    async handleSelectedMetrics (metric) {
      if (this.selectedMetrics?.map(m => m.value)?.includes(metric.value)) {
        this.selectedMetrics = this.selectedMetrics.filter(m => m.value !== metric.value)
      } else if (this.selectedMetrics.length === 2) {
        this.selectedMetrics[1] = metric
      } else {
        this.selectedMetrics.push(metric)
      }

      this.controlTableHeaders()
    },
    selectedDimensionIcon (dimension) {
      if (!this.selectedDimensions?.map(d => d.value)?.includes(dimension.value)) return
      if (this.selectedDimensions[0]?.value === dimension.value) return 'mdi-numeric-1'
      if (this.selectedDimensions[1]?.value === dimension.value) return 'mdi-numeric-2'
    },
    async handleSelectedDimensions (dimension) {
      if (this.selectedDimensions?.map(d => d.value)?.includes(dimension.value)) {
        this.selectedDimensions = this.selectedDimensions.filter(d => d.value !== dimension.value)
      } else if (this.selectedDimensions.length === 2) {
        this.selectedDimensions[1] = dimension
      } else {
        this.selectedDimensions.push(dimension)
      }

      this.controlTableHeaders()
    },
    controlTableHeaders () {
      this.tableArticles.headers = [
        { text: 'URL', value: 'article_url' },
        { text: 'Cluster', value: 'title' }
      ]

      if (this.selectedDimensions.length) {
        this.tableArticles.headers.push(...this.selectedDimensions.map(d => ({ text: d.text, value: d.value })))
      }
      if (this.selectedMetrics.length) {
        this.tableArticles.headers.push(...this.selectedMetrics.map(m => ({ text: m.text, value: m.value })))
      }
      this.tableArticles.headers.push({ text: 'More', value: 'more' })
      this.tableArticlesOptions.sortBy = [this.selectedMetrics[0].value]
    },
    async toggleTrending () {
      this.trending = !this.trending
      this.handleSelectedMetrics({
        text: 'Cluster Impressions Change',
        value: 'google_impressions_change'
      })
    },
    async toggleLosing () {
      this.losing = !this.losing
      this.handleSelectedMetrics({
        text: 'Cluster Impressions Change',
        value: 'google_impressions_change'
      })
    },
    addMetricFilter (metric) {
      metric.min = null
      metric.max = null
      this.metricFilters.push(metric)
    },
    removeMetricFilter (metric) {
      this.metricFilters = this.metricFilters.filter(m => m.value !== metric.value)
      this.mutateArticles()
    },
    addDimensionFilter (dimension) {
      dimension.filter = dimension.type === 'date' ? [null, null] : null
      this.dimensionFilters.push(dimension)
    },
    removeDimensionFilter (dimension) {
      this.dimensionFilters = this.dimensionFilters.filter(d => d.value !== dimension.value)
      this.mutateArticles()
    },

    // ACTIONS - GENERAL
    async recalculate () {
      this.isLoading.alerts = true
      try {
        await this.recalculateAlerts({
          contentplanId: this.contentplanId
        })
      } catch (e) {
        console.log(e)
      }
      this.isLoading.alerts = 'refresh'
    },

    // ACTIONS - KEYWORDS
    async moveSelectedKeywords (articleId) {
      const moveToId = articleId || this.keywordsNewArticle
      if (this.selectedKeywords?.length === 0 || !moveToId) return

      this.isLoading.moveKeywords = true

      try {
        await this.updateKeywords({
          contentplanId: this.contentplanId,
          keywordIds: this.selectedKeywords.map(el => el.keyword_id),
          articleId: moveToId,
          action: 'move'
        })
        this.mutateArticles()
        this.selectedKeywords = []

        this.isLoading.moveKeywords = false
        await this.recalculate()
      } catch (e) {
        console.log(e)
      }
    },
    async excludeSelectedKeywords () {
      if (this.selectedKeywords?.length === 0) return

      this.isLoading.excludeKeyword = true
      const keywordIds = this.selectedKeywords.map(el => el.keyword_id)

      try {
        await this.updateKeywords({
          contentplanId: this.contentplanId,
          keywordIds,
          action: 'exclude'
        })
        this.mutateArticles()
        this.selectedKeywords = []

        this.isLoading.excludeKeyword = false
        await this.recalculate()
      } catch (e) {
        console.log(e)
      }
    },
    async resetKeywords (keywordId) {
      if (!this.selectedKeywords?.length && !keywordId) return

      this.isLoading.resetKeyword = true
      const selectedKeywordIds = this.selectedKeywords.map(el => el.keyword_id)

      const keywordIds = keywordId ? [keywordId] : selectedKeywordIds

      try {
        await this.updateKeywords({
          contentplanId: this.contentplanId,
          keywordIds,
          action: 'reset'
        })
        this.mutateArticles()
        this.selectedKeywords = []

        await this.recalculate()
        this.isLoading.resetKeyword = false
      } catch (e) {
        console.log(e)
      }
    },

    // ACTIONS - ARTICLES
    async mergeSelectedArticles (mergeToId, mergeFromId) {
      if (this.selectedArticles?.length === 0 && (!mergeToId || !mergeFromId)) return

      let mergeToArticleId = mergeToId
      let mergeFromArticleIds = [mergeFromId]
      if (!mergeToArticleId || !mergeFromArticleIds?.length) {
        this.selectedArticles.sort((a, b) => {
          return b.estimated_search_volume - a.estimated_search_volume
        })

        const deleteArticles = this.selectedArticles.map(a => a.article_id)
        deleteArticles.shift()

        mergeToArticleId = this.selectedArticles[0].article_id
        mergeFromArticleIds = deleteArticles
        console.log('mergeSelectedArticles', mergeToArticleId, mergeFromArticleIds)
      }
      if (!mergeToArticleId || !mergeFromArticleIds?.length) return

      this.isLoading.mergeArticles = mergeFromId || true
      try {
        await this.updateArticles({
          contentplanId: this.contentplanId,
          action: 'merge',
          articleIdsFrom: mergeFromArticleIds,
          articleIdTo: mergeToArticleId
        })
        this.mutateArticles()

        this.selectedArticles = []
        this.isLoading.mergeArticles = false
        await this.recalculate()
      } catch (e) {
        console.log(e)
      }
    },
    async moveSelectedArticles (articleId, topicId) {
      if ((this.selectedArticles?.length === 0 || !this.articlesNewTopicId) && (!articleId || !topicId)) return

      const articlesToMove = articleId ? [articleId] : this.selectedArticles.map(el => el.article_id)
      const newTopic = topicId || this.articlesNewTopicId

      if (!articlesToMove?.length || !newTopic) return
      this.isLoading.moveArticles = articleId || true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateTopic',
          articleIds: articlesToMove,
          topicId: newTopic
        })
        this.mutateArticles()

        this.selectedArticles = []
        this.isLoading.moveArticles = false
      } catch (e) {
        console.log(e)
      }
    },
    async excludeSelectedArticles (articleId) {
      const articleIds = articleId ? [articleId] : this.selectedArticles.map(el => el.article_id)
      if (articleIds?.length === 0) return

      this.isLoading.deleteArticles = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'delete',
          articleIds
        })
        this.mutateArticles()

        this.selectedArticles = []
        this.isLoading.deleteArticles = false
        await this.recalculate()
      } catch (e) {
        console.log(e)
      }
    },
    async addToMapSelectedArticles (articleId) {
      const articleIds = articleId ? [articleId] : this.selectedArticles.map(el => el.article_id)
      if (articleIds?.length === 0) return

      this.isLoading.addToMap = articleId || true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'addToMap',
          articleIds
        })
        this.mutateArticles()
        console.log('Articles after addToMap', this.articles)

        this.selectedArticles = []
        this.isLoading.addToMap = false
      } catch (e) {
        console.log(e)
      }
    },
    async dismissSelectedArticles (articleId) {
      const articleIds = articleId ? [articleId] : this.selectedArticles.map(el => el.article_id)
      if (articleIds?.length === 0) return

      this.isLoading.dismissArticles = articleId || true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updatePriority',
          priority: 'low',
          articleIds
        })
        await this.articleExclude({
          articleIds
        })
        this.mutateArticles()

        this.selectedArticles = []
        this.isLoading.dismissArticles = false
      } catch (e) {
        console.log(e)
      }
    },
    async addNewArticle () {
      if (!this.articleNewName) return
      this.isLoading.newArticle = true

      try {
        const newArticleId = await this.addArticle({
          contentplanId: this.contentplanId,
          topicId: this.topicFilter || this.topics.map(t => t.id)[0],
          name: this.articleNewName
        })
        if (this.articleAddAndMoveKeyword) {
          await this.moveSelectedKeywords(newArticleId?.id)
        } else {
          this.mutateArticles()
        }
        this.showArticleAdd = false
        this.articleNewName = null
        this.articleAddAndMoveKeyword = false
        this.showKeywordMove = false
        this.selectedKeywords = []
        this.isLoading.newArticle = false
      } catch (e) {
        console.log(e)
      }
    },

    // ACTIONS - TOPICS
    async addNewTopic () {
      if (!this.topicNewName) return
      this.isLoading.addTopic = true

      try {
        const newTopicId = await this.addTopic({
          contentplanId: this.contentplanId,
          name: this.topicNewName
        })
        this.topicNew({
          topicId: newTopicId?.id,
          name: this.topicNewName,
          contentplanId: this.contentplanId
        })
        if (this.topicAddAndMoveArticle) {
          this.articlesNewTopicId = newTopicId?.id
          await this.moveSelectedArticles()
        }
        this.showTopicAdd = false
        this.showArticleMove = false
        this.topicNewName = null
        this.selectedArticles = []
        this.topicAddAndMoveArticle = false
        this.articlesNewTopicId = null
        this.isLoading.addTopic = false
      } catch (e) {
        console.log(e)
      }
    },
    async renameTopic (topicId) {
      if (!this.topicNewName) return

      this.isLoading.renameTopic = true
      const topicIds = topicId || this.selectedTopic
      if (!topicIds) return

      try {
        await this.updateTopics({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          topicIds,
          name: this.topicNewName,
          action: 'rename'
        })
        this.topicRename({
          topicId: topicIds,
          name: this.topicNewName
        })

        this.selectedTopic = null
        this.showTopicRename = false
        this.topicNewName = null
        this.isLoading.renameTopic = false
      } catch (e) {
        console.log(e)
      }
    },
    async excludeTopic () {
      if (!this.selectedTopic) return
      this.isLoading.excludeTopic = true

      try {
        await this.updateTopics({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          topicIds: this.selectedTopic,
          action: 'exclude'
        })
        this.topicExclude({
          topicIds: [this.selectedTopic]
        })
        this.mutateArticles()

        this.selectedTopic = null
        this.showTopicExclude = false
        this.topicFilter = null
        this.isLoading.excludeTopic = false
        await this.recalculate()
      } catch (e) {
        console.log(e)
      }
    },
    async deleteTopic (topicId) {
      const topicIds = topicId || this.selectedTopic
      if (!topicIds) return

      this.isLoading.deleteTopic = true

      try {
        await this.updateTopics({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          topicIds,
          action: 'delete'
        })
        this.topicExclude({
          topicIds: [topicIds]
        })
        this.mutateArticles()

        this.selectedTopic = null
        this.showTopicReset = false
        this.topicFilter = null
        this.isLoading.deleteTopic = false
      } catch (e) {
        console.log(e)
      }
    },

    // ACTIONS - SELECTION
    async handleCheckboxClick (keyword, article, page) {
      if (keyword) {
        this.selectedArticles = []
        this.selectedPages = []
        const index = this.selectedKeywords.findIndex(el => el.keyword_id === keyword.keyword_id)
        if (index === -1) {
          this.selectedKeywords.push(keyword)
        } else {
          this.selectedKeywords.splice(index, 1)
        }
      }
      if (article) {
        this.selectedKeywords = []
        this.selectedPages = []
        const index = this.selectedArticles.findIndex(el => el.article_id === article.article_id)
        if (index === -1) {
          this.selectedArticles.push(article)
        } else {
          this.selectedArticles.splice(index, 1)
        }
        if (this.selectedArticles?.length === 1) {
          this.articleNewName = article.title
        }
      }
      if (page) {
        this.selectedKeywords = []
        this.selectedArticles = []
        const index = this.selectedPages.findIndex(el => el.page_id === page.page_id)
        if (index === -1) {
          this.selectedPages.push(page)
        } else {
          this.selectedPages.splice(index, 1)
        }
      }

      this.getScore()
    },
    async handleSelectAllClick () {
      if (this.selectAllArticles) {
        this.selectedArticles = this.articles
      } else {
        this.selectedArticles = []
      }

      this.getScore()
    },
    handleExistingPagesClick () {
      if (this.actionFilter) {
        this.actionFilter = ''
        this.mutateArticles()
        return
      }
      this.newOrExistingFilter = this.newOrExistingFilter !== 'existing' ? 'existing' : null
      this.mutateArticles()
    },
    handleNewPagesClick () {
      this.actionFilter = ''
      this.newOrExistingFilter = this.newOrExistingFilter !== 'new' ? 'new' : null
      this.mutateArticles()
    },
    handleMetricFilterChipClick (metric, i) {
      console.log(metric, i)

      // write metric min/max values to this.metricFilters array based on index of ranges
      this.metricFilters.map(m => {
        if (m.value === metric.value) {
          m.min = metric.ranges[i - 1]
          m.max = metric.ranges[i]
        }

        return m
      })

      this.mutateArticles()
    },
    handleGridViewSwitch () {
      if (this.switchGridView) {
        this.mutateArticles()
      }
    },
    isKeywordSelected (keywordId) {
      return this.selectedKeywords.some(el => el.keyword_id === keywordId)
    },
    isArticleSelected (articleId) {
      return this.selectedArticles.some(el => el.article_id === articleId)
    },
    isPageSelected (pageId) {
      return this.selectedPages.some(el => el.page_id === pageId)
    },
    async loadArticleUrlsSelection (articleId) {
      this.isLoading.articleUrls = true

      await this.loadArticleUrls({
        articleId
      })
      this.selectableTargetUrls = this.articleUrls
      // console.log('this.articleUrls', this.articleUrls)

      // this.selectableTargetUrls = this.articles.filter(u => u.article_id !== articleId)
      // console.log('this.selectableTargetUrls', this.selectableTargetUrls)
      // this.selectableTargetUrls = this.selectableTargetUrls.map(iu => {
      //   const urlWithImpressions = this.articleUrls.filter(u => u.page_id === iu.page_id)
      //   console.log('urlWithImpressions', urlWithImpressions)
      //   if (urlWithImpressions.length > 0) {
      //     return { ...iu, impressions: urlWithImpressions[0].impressions }
      //   }
      //   return { ...iu, impressions: 0 }
      // })

      // this.selectableTargetUrls.sort((a, b) => {
      //   if (a.impressions > b.impressions) return -1
      //   if (a.impressions < b.impressions) return 1
      //   if (a.article_id < b.article_id) return -1
      //   if (a.article_id > b.article_id) return 1
      //   return 0
      // })
      // console.log('this.selectableTargetUrls', this.selectableTargetUrls)

      this.isLoading.articleUrls = false
    },
    async loadPageArticlesSelection (pageId) {
      this.isLoading.pageArticles = true

      await this.loadPageArticles({
        pageId
      })
      this.selectableTargetArticles = this.pageArticles

      // this.selectableTargetArticles = this.articles.map(a => {
      //   const articleWithImpressions = this.pageArticles.filter(p => p.article_id === a.article_id)
      //   if (articleWithImpressions.length > 0) {
      //     return {
      //       ...a,
      //       impressions: articleWithImpressions?.[0]?.impressions
      //     }
      //   }
      //   return { ...a, impressions: 0 }
      // })
      // // this.selectableTargetArticles = this.selectableTargetArticles.filter(a => !a.page_id === pageId)

      // // sort by impressions
      // this.selectableTargetArticles.sort((a, b) => {
      //   if (a.impressions > b.impressions) return -1
      //   if (a.impressions < b.impressions) return 1
      //   if (!a.article_url) return -1
      //   if (a.article_url) return 1
      //   return 0
      // })

      this.isLoading.pageArticles = false
    }
  },
  async mounted () {
    await this.loadData()
  }
}
