import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Sidebar',
  data () {
    return {
      linksManaged: [
        { name: 'Home', icon: 'mdi-home', title: 'Home' },
        { name: 'Keywords', icon: 'mdi-format-list-bulleted', title: 'Keywords' },
        { name: 'Pages', icon: 'mdi-format-list-numbered', title: 'Pages' },
        { name: 'Plan', icon: 'mdi-book-open-outline', title: 'Plan' },
        { name: 'Mindmap', icon: 'mdi-map', title: 'Funnel map' },
        { name: 'ContentEditor', icon: 'mdi-pencil-outline', title: 'Editor' },
        { name: 'Sprints', icon: 'mdi-run', title: 'Sprints' },
        { name: 'Report', icon: 'mdi-chart-line', title: 'Report' },
        { name: 'Director', icon: 'mdi-chart-bar', title: 'Director report' }
      ],

      linksSaas: [
        { name: 'PaidClusterer', icon: 'mdi-chart-bubble', title: 'SERP clustering' }
        // { name: 'SaaSSemanticClusterer', icon: 'mdi-tag-multiple', title: 'Semantic clustering' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'workspaces',
      'contentplans',
      'activeWorkspace',
      'activeContentplan'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    unusedContentplans () {
      if (this.workspaces && this.contentplans) {
        return this.workspaces.find(w => w.id === this.workspaceId).paid_plans - this.contentplans.length + 1
      }
    },
    selectableWorkspaces () {
      if (!this.workspaces) return
      return this.workspaces.filter(w => w.id !== this.workspaceId)
    },
    subscriptionTypeIsManaged () {
      return this.activeWorkspace?.type === 'managed'
    }
  },
  methods: {
    ...mapMutations([
      'setSidebar'
    ]),
    async changeWorkspace (workspaceId) {
      this.$router.push({
        name: 'Workspace',
        params: {
          workspaceId
        }
      })

      this.$router.go()
    }
  }
}
