import { mapGetters, mapActions } from 'vuex'

import EditorJS from '@/components/EditorJS/EditorJS.vue'
import { statuses, funnel, types } from '@/utils/clusterVariables.js'

export default {
  name: 'ContentEditor',
  data () {
    return {
      isLoading: {
        articles: false
      },

      tableHeaders: [
        { text: '', value: 'action' },
        { text: 'Title', value: 'final' },
        { text: 'Slug', value: 'slug' },
        { text: 'Last updated', value: 'last_updated' },
        { text: 'Status', value: 'status' }
      ],
      tabs: [
        { text: 'Unpublished', value: 'unpublished' },
        { text: 'All', value: 'all' }
      ],
      activeTab: 0,

      selectedArticle: null,
      statuses,
      funnel,
      types
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    },
    async activeTab () {
      await this.loadData()
    },
    selectedArticle (newVal) {
      if (newVal) {
        window.addEventListener('keydown', this.handleEscKey)
      } else {
        window.removeEventListener('keydown', this.handleEscKey)
      }
    }
  },
  computed: {
    ...mapGetters([
      'topics',
      'articles',
      'activeContentplan'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan ? this.activeContentplan.name : ''
    }
  },
  methods: {
    ...mapActions([
      'loadArticlesPublished',
      'updateArticles'
    ]),
    async loadData () {
      this.isLoading.articles = true
      document.title = 'ContentEditor | ContentGecko'

      try {
        await Promise.all([
          this.loadArticlesPublished({
            contentplanId: this.contentplanId,
            excludePublished: this.activeTab === 0
          })
        ])
      } catch (error) {
        console.error('Error loading data:', error)
      }

      this.isLoading.articles = false
    },
    editArticle (article) {
      this.selectedArticle = article // Set the selected article
    },
    copyArticle (article) {
      if (!article) return

      const markdown = article.final
      // Add the markdown to the clipboard
      navigator.clipboard.writeText(markdown)
    },
    updateFinalDraft (newContent) {
      if (this.selectedArticle) {
        this.selectedArticle.final = newContent // Update the final draft content
      }
    },
    async closeEditor () {
      this.selectedArticle = null // Close the editor by resetting the selected article
      await this.loadData() // Reload
    },
    getStatusColor (status) {
      const statusObj = this.statuses.find(s => s.value === status)
      return statusObj ? statusObj.color : null
    },
    getFunnelColor (funnel) {
      const funnelObj = this.funnel.find(f => f.value === funnel)
      return funnelObj ? funnelObj.color : null
    },
    getTypeColor (type) {
      const typeObj = this.types.find(t => t.value === type)
      return typeObj ? typeObj.color : null
    },
    async setStatus (clusterId, value) {
      if (!value) return
      if (!clusterId) return

      this.isLoading.setStatus = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateStatus',
          articleIds: [clusterId],
          status: value
        })
        await this.loadData()
      } finally {
        this.isLoading.setStatus = false
      }
    },
    async setFunnel (clusterId, value) {
      if (!value) return
      if (!clusterId) return

      this.isLoading.setFunnel = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateFunnel',
          articleIds: [clusterId],
          funnel: value
        })
        await this.loadData()
      } finally {
        this.isLoading.setFunnel = false
      }
    },
    async setType (clusterId, value) {
      if (!value) return
      if (!clusterId) return

      this.isLoading.setType = true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action: 'updateType',
          articleIds: [clusterId],
          type: value
        })
        await this.loadData()
      } finally {
        this.isLoading.setType = false
      }
    },
    handleEscKey (event) {
      if (event.key === 'Escape') {
        this.closeEditor()
      }
    }
  },
  async mounted () {
    await this.loadData()
  },
  components: {
    EditorJS
  }
}
