import { mapGetters, mapActions } from 'vuex'

import parseData from '@/utils/parseData'
import tooltips from '@/utils/tooltips'
import ChartComponent from '@/components/Chart/Chart.vue'

export default {
  name: 'Sprints',
  data () {
    return {
      isLoading: {
        gscData: false,
        changeArticleTags: false
      },

      chartData: null,
      chartOptions: {
        scales: {
          yAxes: [{
            type: 'linear',
            display: true,
            position: 'left',
            id: 'y-axis-1',
            min: 0,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              drawOnChartArea: false
            }
          }],
          xAxes: [{
            type: 'time',
            time: {
              unit: 'month',
              isoWeekday: true,
              displayFormats: {
                day: 'MMM DD',
                week: 'MMM DD',
                month: 'MMM YYYY'
              }
            },
            offset: true,
            display: true,
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              drawOnChartArea: false
            }
          }]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          position: 'nearest'
        },
        legend: {
          display: false
        },
        animation: {
          duration: 1000,
          easing: 'easeInOutQuart'
        },
        responsive: true,
        aspectRatio: 4
      },

      date: [],
      country: 'all',
      aggregation: 'monthly',
      settingsDialog: false,
      selectedMonth: null,
      activeTaskMonth: null,

      articlesByMonth: []
    }
  },
  computed: {
    ...mapGetters([
      'articles',
      'activeContentplan',
      'gsc'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan ? this.activeContentplan.name : ''
    },
    gscErrorResponse () {
      if (!this.gsc) return false
      if (typeof this.gsc.byDate === 'string') {
        console.log(this.gsc.byDate)
        return this.gsc.byDate
      }
      return false
    },
    activeSprint () {
      if (!this.articles) return {}
      return this.articlesByMonth[this.articlesByMonth?.length - 1]
    },
    pastSprints () {
      if (!this.articles) return []
      return this.articlesByMonth.slice(0, -1)
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  methods: {
    parseData,
    tooltips,
    ...mapActions([
      'loadArticles',
      'getGSCDataByDate',
      'updateArticles'
    ]),

    // RENDER DATA
    async loadData () {
      this.isLoading.gscData = true
      document.title = 'Sprints View | ContentGecko'

      // set date to last 12 months
      this.date = this.setDates()

      try {
        await Promise.all([
          this.loadArticles({
            workspaceId: this.workspaceId,
            contentplanId: this.contentplanId,
            filterHasDeadline: true
          }),
          this.getGSCDataByDate({
            contentplanId: this.contentplanId,
            startDate: this.date[0],
            endDate: this.date[1],
            aggregation: this.aggregation,
            country: this.country
          })
        ])
      } catch (error) {
        console.log(error)
      } finally {
        if (!this.gscErrorResponse) {
          this.calcChartData()
        } else {
          this.isLoading.gscData = false
        }
        await this.groupArticlesByMonth()
        this.setActiveSlideGroupItem()
      }
    },
    async refreshGSCData () {
      this.isLoading.gscData = true

      try {
        await Promise.all([
          this.getGSCDataByDate({
            contentplanId: this.contentplanId,
            startDate: this.date[0],
            endDate: this.date[1],
            aggregation: this.aggregation,
            country: this.country
          })
        ])
      } catch (error) {
        console.log(error)
      } finally {
        if (!this.gscErrorResponse) {
          this.calcChartData()
        } else {
          this.isLoading.gscData = false
        }
      }
    },
    async calcChartData () {
      if (!this.gsc.byDate) return

      // const dateArray = this.gsc.byDate?.map(x => x.keys[0])
      const dateArray = this.gsc.byDate?.map(x => x.keys[0])

      this.chartData = {
        labels: dateArray,
        datasets: []
      }

      this.chartData.datasets.push({
        label: 'Clicks',
        backgroundColor: '#bbdefb',
        borderColor: 'white',
        fill: false,
        data: this.gsc.byDate?.map(x => x.clicks),
        yAxisID: 'y-axis-1'
      })

      this.isLoading.gscData = false
    },
    async setActiveSlideGroupItem () {
      this.activeTaskMonth = this.pastSprints.length - 1
    },
    setDates () {
      // establish the date range for the last 12 months
      const today = new Date()

      const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0)
      const firstDayOf12MonthsAgo = new Date(today.getFullYear() - 1, today.getMonth(), 1)

      return [
        this.formatDate(firstDayOf12MonthsAgo),
        this.formatDate(lastDayOfLastMonth)
      ]
    },
    formatDate (date) {
      const d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      const year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    },
    async changeArticleTags (articleId, tag, newValue) {
      if (!articleId || !tag) return

      const articleIds = [articleId]

      let action
      if (tag === 'status') {
        action = 'updateStatus'
      } else if (tag === 'priority') {
        action = 'updatePriority'
      } else if (tag === 'funnel') {
        action = 'updateFunnel'
      }

      this.isLoading.changeArticleTags = articleId || true

      try {
        await this.updateArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          action,
          articleIds,
          [tag]: newValue
        })

        // change the value in the articlesByMonth array
        this.articlesByMonth = this.articlesByMonth.map(group => {
          group.articles = group.articles.map(article => {
            if (article.article_id === articleId) {
              article[tag] = newValue
            }
            return article
          })
          return group
        })

        this.isLoading.changeArticleTags = false
      } catch (e) {
        console.log(e)
      }
    },
    async groupArticlesByMonth () {
      if (!this.articles) return
      // Filter articles with 'status_updated_at'
      const filteredArticles = this.articles.filter(article => article.deadline).sort((a, b) => new Date(a.deadline) - new Date(b.deadline))

      // Define an empty object for the output
      const result = {}

      filteredArticles.forEach(article => {
        // Create a date object
        const date = new Date(article.deadline)

        // Format date as 'Month Year'
        const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' })

        // If the object doesn't have this monthYear property, add it and set its value to an empty array
        if (!result[monthYear]) {
          result[monthYear] = []
        }

        // Push the current article to the corresponding array
        result[monthYear].push(article)
      })

      // Convert the result object into an array of objects
      const output = Object.entries(result).map(([month, articles]) => ({ month, articles }))

      // Add counts to each object
      output.forEach(group => {
        group.totalArticles = group.articles?.length || 0
        group.completedArticles = group.articles?.filter(article => article.status === 'published')?.length || 0
      })

      this.articlesByMonth = output
    },
    completedArticles (obj) {
      return obj?.completedArticles || 0
    },
    totalArticles (obj) {
      return obj?.totalArticles || 0
    },
    articleList (obj) {
      return obj?.articles || []
    }
  },
  components: { ChartComponent },
  async mounted () {
    await this.loadData()
  }
}
